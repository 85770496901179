import React from 'react';
import { Helmet } from 'react-helmet';
import { Global } from '@emotion/core';
import style from './style';

// eslint-disable-next-line @typescript-eslint/prefer-ts-expect-error
// @ts-ignore
import fav1 from '../../src/icons/apple-icon-57x57.png';
// @ts-ignore
import fav2 from '../../src/icons/apple-icon-60x60.png';
// @ts-ignore
import fav3 from '../../src/icons/apple-icon-72x72.png';
// @ts-ignore
import fav4 from '../../src/icons/apple-icon-76x76.png';
// @ts-ignore
import fav5 from '../../src/icons/apple-icon-114x114.png';
// @ts-ignore
import fav6 from '../../src/icons/apple-icon-120x120.png';
// @ts-ignore
import fav7 from '../../src/icons/apple-icon-144x144.png';
// @ts-ignore
import fav8 from '../../src/icons/apple-icon-152x152.png';
// @ts-ignore
import fav9 from '../../src/icons/apple-icon-180x180.png';
// @ts-ignore
import fav10 from '../../src/icons/android-icon-192x192.png';
// @ts-ignore
import fav11 from '../../src/icons/favicon-32x32.png';
// @ts-ignore
import fav12 from '../../src/icons/favicon-96x96.png';
// @ts-ignore
import fav13 from '../../src/icons/favicon-16x16.png';
// @ts-ignore
// import fav14 from '../../src/icons/manifest.json';
// @ts-ignore
import fav15 from '../../src/icons/ms-icon-144x144.png';

interface IndexProps {
  className?: string;
}

const IndexLayout: React.FC<IndexProps> = props => {
  return (
    <div className={props.className}>
      <Helmet>
        <link rel="apple-touch-icon" sizes="57x57" href={fav1} />
        <link rel="apple-touch-icon" sizes="60x60" href={fav2} />
        <link rel="apple-touch-icon" sizes="72x72" href={fav3} />
        <link rel="apple-touch-icon" sizes="76x76" href={fav4} />
        <link rel="apple-touch-icon" sizes="114x114" href={fav5} />
        <link rel="apple-touch-icon" sizes="120x120" href={fav6} />
        <link rel="apple-touch-icon" sizes="144x144" href={fav7} />
        <link rel="apple-touch-icon" sizes="152x152" href={fav8} />
        <link rel="apple-touch-icon" sizes="180x180" href={fav9} />
        <link rel="icon" type="image/png" sizes="192x192" href={fav10} />
        <link rel="icon" type="image/png" sizes="32x32" href={fav11} />
        <link rel="icon" type="image/png" sizes="96x96" href={fav12} />
        <link rel="icon" type="image/png" sizes="16x16" href={fav13} />
        {/* <link rel="manifest" href={fav14} /> */}
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content={fav15} />
        <meta name="theme-color" content="#ffffff" />
      </Helmet>
      <Global styles={style}/>
      {props.children}
    </div>
  );
};

export default IndexLayout;
